import { refreshCart } from './refreshCart';

function addToCartCallback( { cart_modal, el_to_animate_loading, mini_cart } ) {
	mini_cart.classList.add( 'active' );
	document.body.classList.add( 'overflow' );
	cart_modal.classList.add( 'show' );
	el_to_animate_loading.classList.remove( 'loading' );
}

function addToCartErrorCallback( { el_to_animate_loading } ) {
	el_to_animate_loading.classList.remove( 'loading' );
}

window.addEventListener( 'load', () => {
	const mini_cart                    = document.querySelector( '.cart-link' ),
	      cart_modal                   = document.querySelector( '#cart-modal' ),
	      cart_modal_content_container = cart_modal && cart_modal.querySelector( '.cart-content' );

	document.addEventListener( 'click', e => {
		if ( !e.target.closest( '.add_to_cart_button, .btn--remove, .single_add_to_cart_button' ) ) return;

		e.preventDefault();

		const closest_el            = e.target.closest( '.add_to_cart_button, .btn--remove, .single_add_to_cart_button' ),
		      el_to_animate_loading = closest_el.classList.contains( 'btn--remove' ) ? cart_modal_content_container : closest_el,
		      data                  = new FormData(),
		      quantity              = closest_el.dataset.quantity || '';

		mini_cart.classList.remove( 'active' );
		el_to_animate_loading.classList.add( 'loading' );

		data.append( 'product_id', closest_el.dataset.product_id );
		data.append( 'quantity', quantity );
		data.append( 'remove', closest_el.dataset.remove || '' );

		refreshCart( data, {
			callback:              addToCartCallback,
			callback_params:       { cart_modal, el_to_animate_loading, mini_cart },
			error_callback:        addToCartErrorCallback,
			error_callback_params: { el_to_animate_loading },
		} );
	} );
} );
