/**
 * Class for setting and getting cookies from document
 */
export class Cookie {
    /**
     *
     *  cookieContainer {Element | string}
     *  cookieName {string}
     *  cookieValue {string}
     *  cookieExpire {number} days
     *  callback {boolean | Function}
     */
    constructor( { cookieName = 'Cookie', cookieValue = 'accepted', cookieContainer = '', cookieExpire = 1, callback = false } ) {
        this.cookieContainer = cookieContainer instanceof Element ? cookieContainer : (cookieContainer ? document.querySelector( cookieContainer ) : '');
        this.cookieName      = cookieName;
        this.cookieValue     = cookieValue;
        this.cookieExpire    = cookieExpire;
        this.callback        = callback;
    }

    /**
     * Calculate expire time for cookie in days
     * @returns {string}
     */
    calculateTime() {
        const date_obj     = new Date(),
              // Calculate expire days in milliseconds
              expire       = this.cookieExpire * 24 * 60 * 60 * 1000,
              // Get time that cookie was initialized
              current_time = date_obj.getTime();

        // Set new time (expire time) for cookie
        date_obj.setTime( current_time + expire );

        // Return calculated date in UTC String
        return date_obj.toUTCString();
    }

    /**
     * Set cookie
     * @returns {string}
     */
    setCookie() {
        return document.cookie = `${ this.cookieName }=${ this.cookieValue };expires=${ this.calculateTime() };path=/`;
    }

    /**
     * Get searched cookie
     * @param name {string}
     * @returns {string[]}
     */
    getCookie( name ) {
        const all_cookies     = this.getCookies(),
              searched_cookie = all_cookies.filter( cookie => {
                  const cookie_arr = cookie.split( '=' );
                  return cookie_arr[ 0 ].trim() === name ? { name: cookie_arr[ 0 ], value: cookie_arr[ 1 ] } : false;
              } );

        return searched_cookie.length ? searched_cookie : false;
    }

    /**
     * Get all cookies
     * @returns {string[]}
     */
    getCookies() {
        return document.cookie.split( ';' );
    }

    /**
     * Show cookie container
     */
    showCookie() {
        if ( !this.cookieContainer ) {
            console.warn( 'Cannot find cookie container: ', this.cookieContainer );
            return;
        }

        this.btn = this.cookieContainer.querySelector( '.btn--accept' );
        this.cookieContainer.classList.add( 'show' );

        // Attach click event on accept button
        this.btn.addEventListener( 'click', () => {
            this.cookieContainer.classList.remove( 'show' );
            this.setCookie();

            // Get callback if any provided
            if ( this.callback && this.callback instanceof Function ) {
                this.callback();
            }
        } );
    }

    /**
     * Show cookie if there is no saved on users pc
     */
    init() {
        // Check if there is cookie container
        if ( !this.cookieContainer ) {
            return;
        }

        // Check if cookie already accepted
        if ( this.getCookie( this.cookieName ) ) return false;

        this.showCookie();
    }
}
