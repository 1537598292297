/**
 * Function to load deffered images and iframe's when they are in viewport
 *
 * @param observer_options {Object}             Pass Intersection Observer options
 * @param img_to_defer {NodeListOf<Element>}    Default elements are with data-srcset attribute, you may pass any other selector if you like or multiple selectors
 */
function deffer_images( observer_options = {}, img_to_defer = document.querySelectorAll( '[data-srcset]' ) ) {
    // if browser supports intersection observer
    if ( 'IntersectionObserver' in window ) {
        const lazy_image_observer = new IntersectionObserver( entries => {
            entries.forEach( entry => {
                if ( entry.isIntersecting ) {
                    const lazy_image = entry.target;
                    if ( lazy_image.nodeName && lazy_image.nodeName.toLowerCase() === 'iframe' ) {
                        lazy_image.src = lazy_image.dataset.srcset;
                    } else {
                        lazy_image.srcset = lazy_image.dataset.srcset;
                    }
                    lazy_image_observer.unobserve( lazy_image );
                }
            } );
        }, observer_options );
        img_to_defer.forEach( lazyImage => {
            lazy_image_observer.observe( lazyImage );
        } );
        // if browser doesn't support intersection observer
    } else {
        img_to_defer.forEach( element => {
            element.srcset = element.dataset.srcset;
        } );
    }
}

/**
 * Function to load first image of the deffered carousel
 * and then load next image when slides are changing
 * As a parameter pass the carousel that have slides with deffered images
 *
 * @requires jQuery
 *
 * @param carousels {NodeListOf<Element>}
 */
function deffer_carousel( carousels = document.querySelectorAll( '.carousel.defer' ) ) {
    // Load deffered images in carousel
    // Get carousels with class defer, works only on
    // bootstrap carousel event when slide starts changing

    // fill srcset of first image from every carousel on page
    document.querySelectorAll( '.carousel.defer [data-srcset]' ).forEach( image => image.srcset = image.dataset.srcset );
    // fill srcset for deffered images in carousel on carousel slide change
    carousels.forEach( carousel => {
        $( carousel ).on( 'slide.bs.carousel', e => {
            if ( e.to === 0 ) {
                return;
            }

            const target_slide        = e.relatedTarget,
                  // next slide is div.carousel-item so we need to select img and source element's in that element
                  current_slide_image = target_slide.querySelectorAll( '[data-src]' );

            current_slide_image.forEach( image => {
                image.srcset = image.dataset.src;
                // image.classList.add('fade-in');
            } );
        } );
    } );
}

/**
 * Selects all elements that have .bg-deffer class, and also every element need's
 * data attribute where is the url to the image.
 * If there is only one size, then you may pass bool true so function can skip image size checking
 * Available attributes are data-xl, data-lg, data-md, data-sm, data-xs
 * Screen sizes are bootstrap breakpoints:  data-xl min-width 1200px
 *                                          data-lg min-width 992px
 *                                          data-md min-width 768px
 *                                          data-sm min-width 576px
 *                                          data-xs max-width 575px
 *
 * @param oneSize {boolean}                    Check if there is only one size of bg image
 * @param observerOptions {Object}             Pass Intersection Observer options
 * @param bg_to_defer {NodeListOf<Element>}    Default elements are with class .bg-deffer, you may pass any other selector if you like or multiple selectors
 */
function deffer_background( oneSize = false, observerOptions = {}, bg_to_defer = document.querySelectorAll( '.bg-deffer' ) ) {
    // if browser supports intersection observer
    if ( 'IntersectionObserver' in window ) {
        const lazy_background_observer = new IntersectionObserver( entries => {
            entries.forEach( entry => {
                if ( entry.isIntersecting ) {
                    const lazy_item  = entry.target;
                    const image_size = !oneSize ? bg_image_width_exists( window.innerWidth, lazy_item.dataset ) : 'xl';

                    if ( !lazy_item.dataset[ image_size ] ) {
                        return;
                    }

                    lazy_item.style.backgroundImage = `url(${ lazy_item.dataset[ image_size ] })`;
                    lazy_background_observer.unobserve( lazy_item );
                }
            } );
        }, observerOptions );
        bg_to_defer.forEach( lazyImage => {
            lazy_background_observer.observe( lazyImage );
        } );
        // if browser doesn't supports intersection observer
    } else {
        bg_to_defer.forEach( element => {
            const image_size              = bg_image_width_exists( window.innerWidth, element.dataset );
            element.style.backgroundImage = `url("${ element.dataset[ image_size ] }")`;
        } );
    }
}

// Check if item has required dataset image otherwise get dataset with bigger image
/**
 *
 * @param window_width
 * @param dataset_object
 * @returns {string}
 */
function bg_image_width_exists( window_width, dataset_object ) {
    let image_size = 'xl';

    if ( window_width > 1199 ) {
        return image_size;
    }

    if ( window_width <= 575 ) {
        if ( dataset_object.hasOwnProperty( 'xs' ) ) {
            image_size = 'xs';
        } else {
            image_size = bg_image_width_exists( 767, dataset_object );
        }
    }

    if ( window_width > 575 && window_width < 768 ) {
        if ( dataset_object.hasOwnProperty( 'sm' ) ) {
            image_size = 'sm';
        } else {
            image_size = bg_image_width_exists( 991, dataset_object );
        }
    }
    if ( window_width > 767 && window_width < 992 ) {
        if ( dataset_object.hasOwnProperty( 'md' ) ) {
            image_size = 'md';
        } else {
            image_size = bg_image_width_exists( 1199, dataset_object );
        }
    }
    if ( window_width > 991 && window_width < 1200 ) {
        if ( dataset_object.hasOwnProperty( 'lg' ) ) {
            image_size = 'lg';
        } else {
            image_size = bg_image_width_exists( 1200, dataset_object );
        }
    }
    return image_size;
}

export {
    deffer_background,
    deffer_carousel,
    deffer_images
};
