export function refreshCart(data = '', { callback = '', callback_params = {}, error_callback = '', error_callback_params = {} } ) {
	const mini_cart          = document.querySelector( '.cart-link' ),
	      cart_count         = mini_cart && mini_cart.querySelector( '.count' ),
	      cart_modal         = document.querySelector( '#cart-modal' ),
	      cart_modal_content = cart_modal && cart_modal.querySelector( '.cart-content' );

	fetch( '/wp-json/custom/cart', {
		method: 'post',
		body:   data
	} )
		.then( res => res.json() )
		.then( json => {
			// Mini cart animations
			mini_cart.classList.remove( 'cart-empty' );

			// Insert data
			cart_count && ( cart_count.textContent = `(${ json.count })` );
			cart_modal_content.innerHTML = json.layout;

			json.count == 0 ? mini_cart.classList.add( 'cart-empty' ) : mini_cart.classList.remove( 'cart-empty' );

			if ( callback && callback instanceof Function ) {
				callback( callback_params );
			}
		} )
		.catch( err => {
			console.error( err );

			if ( error_callback && error_callback instanceof Function ) {
				error_callback( error_callback_params );
			}
		} );
}
