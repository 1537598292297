import './header';
import './search';
import './cart';
import { Cookie }                                                              from './cookie';
import { refreshCart }                                                         from './refreshCart';
import { ShowElement, HideElement, ToggleElement, RemoveAutoFill, ToTop } from './utils';
import { deffer_background, deffer_images }                                    from './img-defer';
import Carousel                                                                from 'bootstrap/js/src/carousel';
import smoothscroll                                                            from 'smoothscroll-polyfill';

window.addEventListener( 'load', () => {
	smoothscroll.polyfill();
	deffer_images();
	deffer_background();
	refreshCart( '', {} );

	document.querySelectorAll( '.carousel' ).forEach( carousel => {
		new Carousel( carousel );
	} );

	document.addEventListener( 'click', e => {
		if ( !e.target.closest( '[data-open]' ) ) return;
		ShowElement( e.target.closest( '[data-open]' ) );
	} );

	document.addEventListener( 'click', e => {
		const openned_modal = document.querySelector( '.modal.show' ) || document.querySelector( '.navbar-collapse.show' );

		if ( e.target.closest( '[data-close]' ) ) {
			HideElement( e.target.closest( '[data-close]' ) );
		} else if ( e.target == openned_modal ) {
			HideElement( openned_modal );
		}
	} );

	document.addEventListener( 'click', e => {
		if ( !e.target.closest( '[data-togle]' ) ) return;
		ToggleElement( e.target.closest( '[data-togle]' ) );
	} );

	new RemoveAutoFill( document.querySelectorAll( 'form' ) );

	new ToTop( '.btn--top' );

	new Cookie( { cookieContainer: '.cookie', cookieName: 'Lora-Consent', cookieValue: 'accepted', cookieExpire: 30 } ).init();

	// Handle file upload events
	const forms = document.querySelectorAll( 'form' );
	if ( forms.length ) {
		const events = {
			in:  [ 'dragover', 'dragenter' ],
			out: [ 'dragleave', 'dragend', 'drop' ],
			all() {
				return [ 'drag', 'dragstart', ...this.in, ...this.out ];
			}
		};


		forms.forEach( form => {
			const upload_input = form.querySelector( 'input[type="file"]' );

			if ( !upload_input ) {
				return;
			}

			upload_input.addEventListener( 'change', function () {
				const form_group  = this.closest( '.form-group' ),
				      input_label = form_group.querySelector( '.text' );

				input_label.textContent = this.files.length === 0 ? input_label.dataset.empty : this.files[ 0 ].name;
			} );

			// All events
			events.all().forEach( event => {
				form.addEventListener( event, e => {
					e.preventDefault();
					e.stopPropagation();
				} );
			} );

			// Events when dragover
			events.in.forEach( event => {
				form.addEventListener( event, e => {
					form.classList.add( 'drag-over' );
				} );
			} );

			// Events when dragleave
			events.out.forEach( event => {
				form.addEventListener( event, e => {
					form.classList.remove( 'drag-over' );
				} );
			} );

			// Event on dropping file, change label text
			form.addEventListener( 'drop', e => {
				const form_group  = upload_input.closest( '.form-group' ),
				      input_label = form_group.querySelector( '.text' );

				input_label.textContent = e.dataTransfer.files[ 0 ].name;
			} );
		} );
	}

	if ( window.matchMedia( '(max-width: 991px)' ) ) {
		window.requestAnimationFrame( updateViewportHeight );
	}

	const iframes = document.querySelectorAll( 'iframe' );
	iframes.length && iframes.forEach( iframe => iframe.parentElement.classList.add( 'iframe-container', 'embed-16by9' ) );

} );



function updateViewportHeight() {
	document.body.style.setProperty( '--viewport', `${ window.innerHeight }px` );
	window.requestAnimationFrame( updateViewportHeight );
}
