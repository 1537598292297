/**
 * Showing element with css class or jquery methods fadeIn and slideIn
 *
 * @param element {Element}             HTML Element from Document
 * @param InputToFocus {Element|String} Pass HTML element or selector
 * @param elClass {string}              Class to control, default is show
 */
function ShowElement( element, InputToFocus = '', elClass = 'show' ) {
    element.setAttribute( 'aria-expanded', 'true' );
// Get element to open from btn's data-open attribute
    const target_element    = document.querySelector( element.dataset.open ),
          type_of_animation = element.dataset.hasOwnProperty( 'function' ) ? element.dataset.function : null;

    if ( target_element == undefined ) {
        return;
    }

    // If element to open has class modal, add class overflow to body
    // Overflow class is used in css to apply overflow hidden on body
    // to prevent scrolling while modal is openned
    if ( target_element.classList.contains( 'modal' ) || target_element.classList.contains( 'navbar-collapse' ) ) {
        document.body.classList.add( 'overflow' );
    }
    switch ( type_of_animation ) {
        case 'fade':
            $( target_element ).fadeIn();
            break;
        case 'slide':
            $( target_element ).slideDown();
            break;
        default:
            target_element.classList.add( elClass );
    }

    // Focus on input after openning modal if provided
    if ( InputToFocus ) {
        const input_el = InputToFocus instanceof HTMLElement ? InputToFocus : target_element.querySelector( InputToFocus );
        input_el && input_el.focus();
    }
    window.toggled_btn && window.toggled_btn.setAttribute( 'aria-expanded', 'false' );
    window.toggled_btn = element;
    // window.showed_item && window.showed_item.classList.remove( elClass );
    // window.showed_item = target_element;
    new Emmit( target_element, { type: 'show' } );
}

/**
 * Hiding element with css class or jquery methods fadeIn and slideIn
 *
 * @param element {Element}     HTML Element from Document
 * @param elClass {string}      Class to control, default is show
 */
function HideElement( element, elClass = 'show' ) {
    element.setAttribute( 'aria-expanded', 'false' );
    // Get element to open from btn's data-open attribute
    const target_element    = (element.classList.contains( 'modal' ) || element.classList.contains( 'navbar-collapse' )) ?
                              element : document.querySelector( element.dataset.close ),
          type_of_animation = element.dataset.hasOwnProperty( 'function' ) ? element.dataset.function : null;

    if ( target_element == undefined ) {
        return;
    }

    // If element to open has class modal, add class overflow to body
    // Overflow class is used in css to apply overflow hidden on body
    // to prevent scrolling while modal is openned
    if ( target_element.classList.contains( 'modal' ) || target_element.classList.contains( 'navbar-collapse' ) ) {
        document.body.classList.remove( 'overflow' );
    }
    switch ( type_of_animation ) {
        case 'fade':
            $( target_element ).fadeOut();
            break;
        case 'slide':
            $( target_element ).slideUp();
            break;
        default:
            target_element.classList.remove( elClass );
    }

    if ( !target_element.classList.contains( 'mega-menu' ) && target_element.id !== 'search-results-modal' ) {
        window.toggled_btn && window.toggled_btn.setAttribute( 'aria-expanded', 'false' );
        window.toggled_btn = undefined;
    }
    // window.showed_item && window.showed_item.classList.remove( elClass );
    // window.showed_item = undefined;
    new Emmit( target_element, { type: 'close' } );
}

/**
 * Toggles element with css class or jquery methods fadeIn and slideIn
 *
 * @param element {Element}     HTML Element from Document
 * @param elClass {string}      Class to control, default is show
 */
function ToggleElement( element, elClass = 'show' ) {
    element.setAttribute( 'aria-expanded', element.getAttribute( 'aria-expanded' ) === 'true' ? 'false' : 'true' );

    // Get element to open from btn's data-open attribute
    const target_element    = document.querySelector( element.dataset.togle ),
          type_of_animation = element.dataset.hasOwnProperty( 'function' ) ? element.dataset.function : null;

    if ( target_element == undefined ) {
        return;
    }

    // If element to open has class modal, add class overflow to body
    // Overflow class is used in css to apply overflow hidden on body
    // to prevent scrolling while modal is openned
    if ( target_element.classList.contains( 'modal' ) || target_element.classList.contains( 'navbar-collapse' ) ) {
        document.body.classList.toggle( 'overflow' );
    }
    switch ( type_of_animation ) {
        case 'fade':
            $( target_element ).fadeToggle();
            break;
        case 'slide':
            $( target_element ).slideToggle();
            break;
        default:
            target_element.classList.toggle( elClass );
    }

    window.toggled_btn = window.toggled_btn ? undefined : element;
    new Emmit( target_element, { type: 'toggle' } );
}

/**
 * Add class on header when document is scrolled pass header height
 * @param header {Element | string}
 * @param callback
 */
function HeaderControl( header, callback = false ) {
    const header_el     = header instanceof Element ? header : document.querySelector( header ),
          header_height = header_el.offsetHeight;

    if ( window.scrollY >= header_height ) {
        header_el.classList.add( 'scrolled' );
    } else {
        header_el.classList.remove( 'scrolled' );
    }

    if ( callback ) {
        callback( header_el );
    }

    window.requestAnimationFrame( () => {
        HeaderControl( header_el, callback );
    } );
}

/**
 * Animate elements height to original
 * @param element
 * @param classCheck {string}
 * @constructor
 */
function HeightAnim( element, classCheck = 'show' ) {
    if ( !element ) {
        console.error( 'There is no element: ', element );

        return;
    }

    element.style.height = 'auto';
    const item_height    = element.classList.contains( classCheck ) ? element.offsetHeight : 0;
    element.style.height = '0px';
    setTimeout( () => {
        element.style.height = item_height + 'px';
    }, 50 );
}

/**
 *
 * @param elementToCheck {Element}
 * @param elementToAnimate {Element}
 * @param classCheck
 * @param minHeight {number}
 * @constructor
 */
function HeightAnim_2( elementToCheck, elementToAnimate, minHeight = 0, classCheck = 'show' ) {
    elementToAnimate.style.height = 'auto';
    const item_height             = elementToCheck.classList.contains( classCheck ) ? elementToAnimate.offsetHeight : minHeight;
    elementToAnimate.style.height = minHeight + 'px';
    setTimeout( () => {
        elementToAnimate.style.height = item_height + 'px';
    }, 50 );
}

/**
 * Remove autofill from forms on page
 * @param forms
 * @constructor
 */
function RemoveAutoFill( forms ) {
    if ( !forms ) {
        return;
    }

    if ( forms instanceof HTMLElement ) {
        forms.setAttribute( 'autocomplete', 'off' );
        return;
    }

    forms.forEach( form => form.setAttribute( 'autocomplete', 'off' ) );
}

/**
 * Emits custom event
 *
 * @param element {Element, string}
 * @param type
 * @param bubbles
 * @param cancelable
 * @constructor
 */
function Emmit( element, { type, bubbles = true, cancelable = false } ) {
    const event = document.createEvent( 'HTMLEvents' ),
          el    = element === '' ? document : element instanceof Element ? element : document.querySelector( element );
    if ( !el ) {
        console.warn( 'Cannot find element', element );
        return;
    }

    event.initEvent( type, bubbles, cancelable );
    el.dispatchEvent( event );
}

/**
 *
 * Controls anchor links or buttons in content for scrolling to element
 * Anchor needs to have anchor class and hash to the element
 * Button needs to have data-scroll attribute
 *
 * @param selector {string}
 * @param correction {number}
 * @constructor
 */
function AnchorScroll( selector, correction = 0 ) {
    this.selector   = selector;
    this.correction = correction;

    if ( !this.selector ) {
        console.warn( 'You must provide a selector!' );
        return;
    }

    document.addEventListener( 'click', e => {
        if ( !e.target.closest( this.selector ) ) return;

        const closest_el = e.target.closest( this.selector );
        let target_element_selector;

        if ( closest_el.tagName === 'A' ) {
            e.preventDefault();
            target_element_selector = closest_el.hash;
        } else {
            target_element_selector = closest_el.dataset.scroll;
        }


        const target_el = document.querySelector( target_element_selector );

        if ( !target_el ) {
            return;
        }

        setTimeout( () => {
            window.scroll( {
                               top     : Offset( target_el ).top - this.correction,
                               behavior: 'smooth'
                           } );
        }, 50 );
    } );
}

/**
 * Scroll to top
 * Controls showing button and handles click event to scroll to top
 *
 */
class ToTop {
    /**
     * @param btn {string, Element} - HTML button element
     * @param amount_to_scroll {number} - amount to scroll in pixels
     */
    constructor( btn, amount_to_scroll = 800 ) {
        this.btn              = btn instanceof Element ? btn : document.querySelector( btn );
        this.amount_to_scroll = amount_to_scroll;

        if ( !this.btn ) {
            console.warn( `Cannot find or invalid selector for btn: '${ btn }'` );
            return;
        }

        this.showToTop();
        this.attachClick();
    }

    /**
     * Checks if document is scrolled enough to show btn
     */
    showToTop() {
        window.pageYOffset > this.amount_to_scroll ? this.btn.classList.add( 'show' ) : this.btn.classList.remove( 'show' );

        requestAnimationFrame( () => {
            this.showToTop( this.btn, this.amount_to_scroll );
        } );
    }

    /**
     * Attaches click event on button
     */
    attachClick() {
        this.btn.addEventListener( 'click', this.scrollToTop );
    }

    scrollToTop() {
        window.scroll( {
                           top     : 0,
                           behavior: 'smooth'
                       } );
    }
}

/**
 * Add id's to headings for table of contents. Add event on click to scroll to clicked heading
 */
class TOC {
    /**
     *
     * @param toc_element
     * @param headingsWrapper
     * @param scrollOnClick
     * @param correction
     */
    constructor( { toc_element = '[data-toc]', headingsWrapper = '', scrollOnClick = true, correction = 0 } ) {
        this.toc_element = toc_element instanceof Element ? toc_element : document.querySelector( toc_element );
        if ( !this.toc_element ) return;

        this.links         = this.toc_element.querySelectorAll( 'a' );
        this.correction    = correction;
        this.scrollOnClick = scrollOnClick;

        // Headings to get from document
        this.headings = headingsWrapper ?
                        document.querySelectorAll( `${ headingsWrapper } ${ this.toc_element.dataset.toc }` ) :
                        document.querySelectorAll( this.toc_element.dataset.toc );

        if ( !this.headings ) {
            console.warn( 'There are no headings.' );
            return;
        }

        this.setId();

        if ( !this.links.length ) {
            this.createAnchors();
        }

        this.scrollOnClick && this.addScroll();
    }

    setId() {
        this.headings.forEach( ( heading, index ) => heading.id = `heading-${ index + 1 }` );
    }

    addScroll() {
        document.addEventListener( 'click', e => {
            if ( !e.target.closest( `.${ this.toc_element.classList[ 0 ] } a` ) ) return;

            e.preventDefault();

            const closest_el = e.target.closest( `.${ this.toc_element.classList[ 0 ] } a` ),
                  target_el  = document.querySelector( closest_el.hash );

            if ( !target_el ) {
                return;
            }

            setTimeout( () => {
                window.scroll( {
                                   top     : Offset( target_el ).top - this.correction,
                                   behavior: 'smooth'
                               } );
            }, 50 );
        } );
    }

    createAnchors() {
        const anchorsFragment = document.createDocumentFragment();

        this.headings.forEach( heading => {
            const anchor       = document.createElement( 'a' );
            anchor.href        = `#${ heading.id }`;
            anchor.textContent = heading.textContent;
            anchorsFragment.appendChild( anchor );
        } );

        this.toc_element.appendChild( anchorsFragment );
    }
}

/**
 * Get elements position relative to document
 *
 * @param el
 * @returns {{top: *, left: *}}
 * @constructor
 */
function Offset( el ) {
    const rect       = el.getBoundingClientRect(),
          scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop  = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export {
    ShowElement,
    HideElement,
    ToggleElement,
    HeaderControl,
    TOC,
    Emmit,
    ToTop,
    HeightAnim,
    HeightAnim_2,
    RemoveAutoFill,
    AnchorScroll,
    Offset
};
