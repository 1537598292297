import { Fetcher } from './fetcher';

function composeSearchedPosts( posts ) {
    let layout = '';

    if ( Array.isArray( posts ) ) {
        posts.forEach( ( { title, image, link } ) => {
            layout += `<a href="${ link }">
                            ${ image ? `<div class="product__image">${ image }</div>` : '' }
                            <h2 class="product__title">${ title }</h2>
                        </a>`;
        } );
    } else {
        layout = `<p class="no-result">${ posts }</p>`;
    }

    return layout;
}

function composeSearchedTerms( terms ) {
    let layout = '';

    if ( Array.isArray( terms ) ) {
        terms.forEach( ( { title, link } ) => {
            layout += `<a href="${ link }">${ title }</a>`;
        } );
    } else {
        layout = `<p class="no-result">${ terms }</p>`;
    }

    return layout;
}

function renderSearchedItems( data, { modal, search_input_container, post_output, terms_output } ) {
    if ( data.error ) {
        post_output.innerHTML  = data.error;
        terms_output.innerHTML = data.error;
    } else {
        post_output.innerHTML  = composeSearchedPosts( data.posts );
        terms_output.innerHTML = composeSearchedTerms( data.terms );
    }

    modal.classList.add( 'show' );
    document.body.classList.add( 'overflow' );
    search_input_container.classList.remove( 'active' );

    showBack( modal );
}

function showModal( modal ) {
    modal.classList.add( 'show' );
    document.body.classList.add( 'overflow' );
    showBack( modal );
}

// Show back btn on mobile menu
function showBack( modal ) {
    if ( window.matchMedia( '(min-width: 992px)' ).matches ) {
        return
    }

    const back_btn = document.querySelector( '#header .btn--back' );

    if ( back_btn ) {
        back_btn.dataset.close = `#${ modal.id }`;
        back_btn.classList.add( 'show' );
        back_btn.addEventListener( 'click', () => {
            back_btn.classList.remove( 'show' );
        } );
    }
}

function hideModal( modal, time = 500 ) {
    return setTimeout( () => {
        modal.classList.remove( 'show' );
        document.body.classList.remove( 'overflow' );
    }, time );
}

function handleError( error, { search_input_container } ) {
    console.error( error );

    search_input_container.classList.remove( 'active' );
}

function updateViewport() {
    document.documentElement.style.setProperty( '--viewport', window.innerHeight + 'px' );
    requestAnimationFrame( updateViewport );
}

window.addEventListener( 'load', () => {
    const search_form              = document.querySelector( '#search-form' ),
          search_input             = search_form.querySelector( '#search' ),
          search_input_container   = search_input.parentElement,
          search_modal             = document.querySelector( '#search-results-modal' ),
          search_results_container = search_modal.querySelector( '.search__results' ),
          post_output              = search_modal.querySelector( '.products__results' ),
          terms_output             = search_modal.querySelector( '.terms__results' ),
          fetch_items              = new Fetcher(
              '/wp-json/custom/search',
              renderSearchedItems,
              {
                  modal                 : search_modal,
                  search_input_container: search_input_container,
                  post_output           : post_output,
                  terms_output          : terms_output
              },
              handleError,
              {
                  search_input_container: search_input_container
              }
          );
    let searchTimeout              = null,
        modal_hide                 = null,
        modal_hovered              = false,
        input_has_focus            = false;

    if ( search_input ) {
        search_input.addEventListener( 'keyup', () => {
            // Clear timout if user didn't finish entering value
            clearTimeout( searchTimeout );

            // Start searching when user enters more than 3 letters
            if ( search_input.value.length <= 3 ) {
                return;
            }

            // Set throttling to wait when use finishes typing
            searchTimeout = setTimeout( () => {
                fetch_items.parameters = fetch_items.composeParameters( [ [ 's', search_input.value ] ] );
                fetch_items.GET();
                search_input_container.classList.add( 'active' );
            }, 350 );
        } );

        // Show modal on input focus if modal not empty
        search_input.addEventListener( 'focus', () => {
            input_has_focus = true;

            clearTimeout( modal_hide );
            if ( post_output.childElementCount || terms_output.childElementCount ) {
                showModal( search_modal );
            }
        } );

        if ( window.matchMedia( '(min-width: 992px)' ).matches ) {

            // Hide modal when input not on focus
            search_input.addEventListener( 'blur', () => {
                input_has_focus = false;

                if ( modal_hovered ) {
                    return;
                }

                modal_hide = hideModal( search_modal );
            } );

            search_results_container.addEventListener( 'mouseenter', () => {
                clearTimeout( modal_hide );
                modal_hovered = true;
            } );

            search_results_container.addEventListener( 'mouseleave', () => {
                if ( !input_has_focus ) {
                    modal_hide = hideModal( search_modal );
                }
                modal_hovered = false;
            } );
        } else {
            window.requestAnimationFrame( updateViewport );
        }
    }
} );
