import { Emmit, HeaderControl, HeightAnim } from './utils';

window.addEventListener( 'load', () => {
	const media_under_991 = window.matchMedia( '(max-width: 991px)' ).matches,
	      mega_menus      = document.querySelectorAll( '.mega-menu' );

	// Control header on mobile
	if ( media_under_991 ) {
		document.addEventListener( 'click', e => {
			if ( !e.target.closest( '#main-navigation .menu-item-has-children' ) && !e.target.closest( '.toggler--navbar' ) ) return;
			const closest_element = e.target.closest( '#main-navigation .menu-item-has-children' ) || e.target.closest( '.toggler--navbar' ),
			      navbar          = closest_element.dataset.togle ? document.querySelector( closest_element.dataset.togle + ' .navbar-nav' ) : closest_element.parentElement,
			      items           = [ ...navbar.children ];
			items.forEach( item => {
				const sub_menu = item.querySelector( '.sub-menu' );

				if ( item.classList === closest_element.classList ) {
					closest_element.classList.toggle( 'open' );
					sub_menu && sub_menu.classList.toggle( 'open' );
				} else {
					item.classList.remove( 'open' );
					sub_menu && sub_menu.classList.remove( 'open' );
				}

				sub_menu && new HeightAnim( sub_menu, 'open' );
			} );
		} );

		const primary_nav         = document.querySelector( '.primary_navigation .navbar-nav' ),
		      nav_container       = primary_nav.parentElement,
		      secondary_nav_items = [ ...document.querySelectorAll( '.secondary-nav .navbar-nav > li' ) ],
		      search_container    = document.querySelector( '#search-form' ),
		      search_modal        = document.querySelector( '#search-results-modal' );

		// Append search to nav
		if ( search_container ) {
			search_container.classList.add( 'show' );

			// Append search results modal to search form
			search_container.appendChild( search_modal );

			nav_container.insertBefore( search_container, primary_nav );
		}

		// Label main menu links and last main menu link
		for ( let i = 0; i < primary_nav.childElementCount; i++ ) {
			primary_nav.children[ i ].classList.add( 'main-menu' );
			if ( i === primary_nav.childElementCount - 1 ) {
				primary_nav.children[ i ].classList.add( 'main-menu--last' );
			}
		}

		// Move secondary nav links to primary
		if ( primary_nav && secondary_nav_items.length ) {
			const fragment = document.createDocumentFragment();
			fragment.append( ...secondary_nav_items );

			primary_nav.append( fragment );
		}

		// Handle show/hide back arrow in menu
		document.addEventListener( 'show', e => {
			if ( !e.target.closest( '.mega-menu' ) ) return;

			const closest_el = e.target.closest( '.mega-menu' ),
			      back_btn   = document.querySelector( '#header .btn--back' );

			if ( back_btn ) {
				back_btn.dataset.close = `#${ closest_el.id }`;
				back_btn.classList.add( 'show' );
				back_btn.addEventListener( 'click', () => {
					back_btn.classList.remove( 'show' );
				} );
			}
		} );

		// Control for mobile mega menu
		document.addEventListener( 'click', e => {
			if ( !e.target.closest( '[data-menu]' ) ) return;

			const closest_el = e.target.closest( '[data-menu]' ),
			      target_el  = closest_el.querySelector( '.mega-menu' );

			if ( target_el ) {
				target_el.classList.add( 'show' );
				new Emmit( target_el, { type: 'show' } );
			}
		} );

		// Toggle navbar toggler on search icon click
		const search_openner = document.querySelector( '.btn--search-openner' );
		search_openner && search_openner.addEventListener( 'click', () => {
			search_openner.nextElementSibling.ariaExpanded = 'true';
		} );
	}

	if ( !media_under_991 ) {
		const mega_menu = document.querySelectorAll( '.mega-menu .container' );

		mega_menu.length && mega_menu.forEach( menu => {
			let mega_menu_timer = null;

			menu.addEventListener( 'mouseover', () => {
				mega_menu_timer && clearTimeout( mega_menu_timer );
				menu.parentElement.classList.add( 'show' );
			} );

			menu.addEventListener( 'mouseout', () => {
				mega_menu_timer = setTimeout( () => {
					menu.parentElement.classList.remove( 'show' );
				}, 150 );
			} );
		} );
	}



	const element = document.querySelectorAll('.right .megamenu');

	console.log(element);


	element.forEach(function(item){
		item.addEventListener('click', function(){
			element.forEach(function(key){
				key.classList.remove('show');
			})
			item.classList.add('show');
		})
	})





		// document.addEventListener("click", function(e) {
		// 	if(!e.target.closest('.right .megamenu')) return;
		//
		// 	e.preventDefault();
		// 	var elems = document.querySelectorAll('.megamenu.show');
		// 	elems.forEach(function(e) {
		// 		e.classList.remove('show');
		// 	})
		// });



	// Animate header on scroll
	HeaderControl( '#header', header => {
		!media_under_991 && mega_menus.length && mega_menus.forEach( menu => menu.style.top = header.getBoundingClientRect().bottom + 'px' );
	} );
} );

// window.addEventListener('click', function(e){
// 	if (!document.getElementById('small-nav').contains(e.target) && e.target !== document.querySelector('.toggler--navbar')){
// 	document.getElementById('nav-modal').classList.remove('show');
// 	}
// })
