export class Fetcher {
	/**
	 *
	 * @param url {string}
	 * @param callback {function}
	 * @param callback_arguments {object}
	 * @param error_callback {null | function}
	 * @param error_callback_arguments {object}
	 */
	constructor( url, callback, callback_arguments = {}, error_callback = null, error_callback_arguments = {} ) {
		this.url                      = url;
		this.callback                 = callback;
		this.callback_arguments       = callback_arguments;
		this.error_callback           = error_callback;
		this.error_callback_arguments = error_callback_arguments;
	}

	/**
	 * Compose parameters for fetch request
	 * Parameters must be an array of arrays => [[name_1, value_1], [name_2, name_2], ...]
	 * @param parameters
	 * @returns {*}
	 */
	composeParameters( parameters ) {
		if ( !parameters ) {
			return '';
		}

		if ( !Array.isArray( parameters ) ) {
			return JSON.stringify( parameters );
		}

		this.parameters = parameters.map( ( parameter, index ) => `${ index === 0 ? '?' : '&' }${ parameter[ 0 ] }=${ parameter[ 1 ] }` );

		return this.parameters;
	}

	/**
	 * Compose parameters for post request
	 *
	 * @returns {{headers: {Accept: string, 'Content-Type': string}, method: string, body}}
	 */
	createPostData() {
		return {
			method:  'POST',
			headers: {
				'Accept':       'application/json',
				'Content-Type': 'application/json'
			},
			body:    this.parameters
		};
	}

	/**
	 * Make GET Request
	 *
	 * @constructor
	 */
	GET() {
		fetch( this.url + this.parameters )
			.then( result => result.json() )
			.then( response => {
				this.callback( response, this.callback_arguments );
			} )
			.catch( error => {
				if ( this.error_callback ) {
					this.error_callback( error, this.error_callback_arguments );
				} else {
					console.error( 'There has been an error: ', error );
				}
			} );
	}

	/**
	 * Make POST Request
	 *
	 * @constructor
	 */
	POST() {
		fetch( this.url, this.createPostData() )
			.then( result => result.json() )
			.then( response => {
				this.callback( response, this.callback_arguments );
			} )
			.catch( error => {
				if ( this.error_callback ) {
					this.error_callback( error, this.error_callback_arguments );
				} else {
					console.error( 'There has been an error: ', error );
				}
			} );
	}
}
